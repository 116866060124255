import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  useMediaQuery,
  ListItem,
  MenuList,
  ListItemButton,
  List,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import meetDeb from "../images/about_deb_bg.jpg";
import targetImage from "../images/home_bg.jpg";
import caseStudiesBg from "../images/case_studies_bg.jpg";
import {
  AccountBalance,
  Assignment,
  AttachMoney,
  Forest,
  Gavel,
  House,
  School,
  SsidChart,
  Work,
} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  
  header: {
    backgroundImage: `url(${targetImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff",
    padding: theme.spacing(8, 2),
    height: "100vh",
    textAlign: "center",
  },
  testimonialSection: {
    padding: theme.spacing(8, 0),
    textAlign: "center",
  },
  testimonial: {
    padding: theme.spacing(2),
    position: "relative",
    "&:before": {
      content: '"\\201c"',
      fontFamily: '"Times New Roman", Times, serif',
      display: "block",
      fontSize: "72px",
      lineHeight: "50px",
      fontWeight: 700,
      textAlign: "left",
      position: "static",
      color: "#FFFFFF",
    },
  },
  avatar: {
    margin: "auto",
    width: `${theme.spacing(13)} !important`,
    height: `${theme.spacing(13)} !important`,
  },
  quote: {
    margin: "4rem 0rem!important",
    fontStyle: "normal",
    fontWeight: "500",
    maxWidth: "50vw",
    fontSize: "16px",
    lineHeight: "1.6875",
    fontFamily: "'Open Sans', sans-serif",
    letterSpacing: "0em",
    color: "#FFFFFF",
    textAlign: "left",
  },
  name: {
    fontWeight: "bold",
  },
  position: {
    color: theme.palette.text.secondary,
  },
  servicesSection: {
    textAlign: "center",
  },
  serviceItem: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  serviceImage: {
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  readMoreButton: {
    marginTop: theme.spacing(2),
    textTransform: "none!important",
    backgroundColor: "#005978!important",
    padding: "11px 30px!important",
    borderRadius: "24px!important",
    letterSpacing: "0.04em!important",
    borderWidth: "0!important",
  },
  caseStudiesSection: {
    backgroundImage: `url(${caseStudiesBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff",
    padding: theme.spacing(8, 2),
    textAlign: "left",
  },
  caseStudyItem: {
    padding: theme.spacing(2),
  },
  caseStudyTitle: {
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
  caseStudyDescription: {
    color: "#e0e0e0",
  },
}));

const Home = () => {
  const [showDonate, setShowDonate] = useState(false);

useEffect(() => {
  const handleScroll = () => {
    setShowDonate(window.scrollY > 100);
  };

  window.addEventListener("scroll", handleScroll);
  return () => window.removeEventListener("scroll", handleScroll);
}, []);


  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <div>
      <Box className={classes.header} sx={{backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", height: isMobile ? "auto" : "100vh",}}>
        <Grid
          container
          mt={8}
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        ></Grid>
      </Box>

      <Grid
        id="meet-deb"
        container
        display="flex"
        justifyContent="center"
        mb={6}
        p={6}
        flexDirection="column"
        sx={{
          background: `url(${meetDeb})`,
        }}
        alignItems="center"
      >
        <Grid item mb={4}>
          <Typography
            variant="h1"
            color="#FFFFFF!important"
            sx={{ fontSize: "2.5rem!important" }}
          >
            ABOUT DEB
          </Typography>
        </Grid>
        {showDonate && (
        <Grid className="donate-top" sx={{ margin: !isMobile ? "0!important" : "1rem 0!important"}}>
              <List>
                <ListItemButton
                  component="a"
                  href="https://secure.ngpvan.com/gZxHJ61qgEyCR0_1Smu0xg2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItemText primary="DONATE" />
                </ListItemButton>
              </List>
              </Grid>
              )}
        <Grid
          item
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            maxWidth: !isMobile ? "50vw" : "auto",
            fontSize: "16px",
            lineHeight: "1.6875",
            fontFamily: "'Open Sans', sans-serif",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#FFFFFF",
            mb: 2,
          }}
        >
          <Typography variant="p">
            Since her election to the County Council in 2018, Deb Jung has been
            a staunch advocate for all of the residents of Howard County while
            proudly representing District 4, which includes five of the ten
            Columbia Villages, as well as Fulton, Maple Lawn, and parts of
            Clarksville, Scaggsville, and North Laurel.
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            maxWidth: !isMobile ? "50vw" : "auto",
            fontSize: "16px",
            lineHeight: "1.6875",
            fontFamily: "'Open Sans', sans-serif",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#FFFFFF",
            mb: 2,
          }}
        >
          <Typography variant="p">
            Deb has served as Council Chair twice in the past six years.
            Throughout her service on the Council, she has worked tirelessly to
            ensure that our community is a great place to live, work, and raise
            healthy families. She places people before profit, and is not afraid
            to fight back against special interests or cast a vote for what she
            thinks is right rather than politically expedient.
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            maxWidth: !isMobile ? "50vw" : "auto",
            fontSize: "16px",
            lineHeight: "1.6875",
            fontFamily: "'Open Sans', sans-serif",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#FFFFFF",
            mb: 2,
          }}
        >
          <Typography variant="p">
            Deb is running for Howard County Executive as your advocate – and
            she’s committed to put people first, every step of the way. As a
            Citizens’ Election Fund candidate, her campaign is powered 100% by
            small donations from people like you. She won’t take a dime from
            special interest groups, corporations or outsider PACS, who
            frequently seek to influence political outcomes.
          </Typography>
        </Grid>
        <br></br>
        <Grid
          item
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            maxWidth: !isMobile ? "50vw" : "auto",
            fontSize: "16px",
            lineHeight: "1.6875",
            fontFamily: "'Open Sans', sans-serif",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#FFFFFF!important",
            mt: 2,
            mb: 4,
          }}
        >
          <Typography variant="h3" sx={{ color: "#FFFFFF!important" }}>
            As a candidate for Howard County Executive, her priorities include:
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            maxWidth: !isMobile ? "50vw" : "auto",
            fontSize: "16px",
            lineHeight: "1.6875",
            fontFamily: "'Open Sans', sans-serif",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#FFFFFF",
            mb: 2,
          }}
        >
          <MenuList>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <School sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Investing in Howard County’s public schools to ensure the
              continued success of our students"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Forest sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Protecting our environment and natural resources, and combating
              the effects of climate change"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <AccountBalance sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Improving the transparency and accountability of County government
              and reducing barriers to citizen oversight and participation in
              land-use proceedings"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <House sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                  primary=" Making it possible for seniors to age-in-place through expanding
              existing tax credits, housing options and resources"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <SsidChart sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Prioritizing economic development that contributes to job growth
              in targeted business sectors such as professional, scientific, and
              technical services, wholesale trade, and construction."
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Work sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Prioritizing economic development that contributes to job growth
              in targeted business sectors such as professional, scientific, and
              technical services, wholesale trade, and construction."
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Gavel sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Creating guardrails against unlawful discrimination and promoting
              racial and economic justice"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <AttachMoney sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Managing population growth and increasing our housing supply the
              right way, without cutting corners or violating the Adequate
              Public Facilities Ordinance. Pursuing pathways to home ownership
              that is affordable and accessible. Ensuring that taxpayer dollars
              are used efficiently and prudently in order to maintain a balanced
              budget in years to come"
                />
              </ListItemButton>
            </ListItem>
          </MenuList>
        </Grid>
        <br></br>
        <Grid
          container
          className={classes.servicesSection}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          mt={2}
          mb={4}
        >
          <Typography
            variant="h1"
            color="#FFFFFF!important"
            sx={{ fontSize: "2.5rem!important" }}
          >
            LEARN MORE ABOUT DEB
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            maxWidth: !isMobile ? "50vw" : "auto",
            fontSize: "16px",
            lineHeight: "1.6875",
            fontFamily: "'Open Sans', sans-serif",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#FFFFFF",
            mb: 2,
          }}
        >
          <Typography variant="p">
            Deb and her husband Ben have lived in Columbia for 37 years, where
            they raised their now-grown daughter, Alyssa, a Wilde Lake graduate
            (Go Cats!).
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            maxWidth: !isMobile ? "50vw" : "auto",
            fontSize: "16px",
            lineHeight: "1.6875",
            fontFamily: "'Open Sans', sans-serif",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#FFFFFF",
            mb: 2,
          }}
        >
          <Typography variant="p">
            Deb is passionate about the community she calls home. She has served
            Howard County for many years as a civic leader. Prior to her
            election to the County Council, she was appointed Co-Chair of the
            Howard County Sesquicentennial Celebration Committee; Co-Chair of
            the Martin Luther King, Jr. Holiday Commission; and held a five-year
            term as member of the Howard County Human Rights Commission. She
            also co-founded the Executive’s Charity for the Arts, and served as
            its President, working to secure arts-related scholarships for
            Howard County residents.
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            maxWidth: !isMobile ? "50vw" : "auto",
            fontSize: "16px",
            lineHeight: "1.6875",
            fontFamily: "'Open Sans', sans-serif",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#FFFFFF",
            mb: 2,
          }}
        >
          <Typography variant="p">
            In addition to her community work, she was an active parent
            volunteer in the Howard County Public Schools System. She served two
            years as the Booster Club President at Wilde Lake High School and
            was a member of the HCPSS School Health Council. She also helped
            lead the Start School Later effort to change start times for Howard
            County high-school students.
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            maxWidth: !isMobile ? "50vw" : "auto",
            fontSize: "16px",
            lineHeight: "1.6875",
            fontFamily: "'Open Sans', sans-serif",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#FFFFFF",
            mb: 2,
          }}
        >
          <Typography variant="p">
            Prior to her initial election to the Council in 2018, Deb was a
            private practice attorney providing legal advice, training, and
            consulting to nonprofit organizations throughout Maryland. Earlier
            in her career, she was a litigation associate at Whiteford, Taylor &
            Preston in Baltimore; General Counsel to the Close-Up Foundation, a
            civic-education organization for high-school students and teachers;
            and General Counsel to the Maryland Association of Nonprofit
            Organizations in Baltimore.
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            maxWidth: !isMobile ? "50vw" : "auto",
            fontSize: "16px",
            lineHeight: "1.6875",
            fontFamily: "'Open Sans', sans-serif",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#FFFFFF",
            mb: 2,
          }}
        >
          <Typography variant="p">
            Deb has been active in the women’s rights movement for decades, and
            worked as an organizer for the National Organization for Women in
            the early 1980s.
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            maxWidth: !isMobile ? "50vw" : "auto",
            fontSize: "16px",
            lineHeight: "1.6875",
            fontFamily: "'Open Sans', sans-serif",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#FFFFFF",
            mb: 2,
          }}
        >
          <Typography variant="p">
            A lifelong Marylander, Deb graduated from Severna Park High School.
            She earned a Bachelor of Arts in Political Science from Duke
            University and a Juris Doctorate from the University of Maryland
            School of Law.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        id="learn-more-deb"
        container
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        pb={4}
      >
        <Grid
          item
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            maxWidth: !isMobile ? "50vw" : "auto",
            fontSize: "16px",
            lineHeight: "1.6875",
            fontFamily: "'Open Sans', sans-serif",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#FFFFFF!important",
            mt: 2,
            mb: 4,
          }}
        >
          <Typography variant="h3" sx={{ color: "#106cb7 !important" }}>
            Join Deb in her movement to build a stronger, more transparent
            Howard County.
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            maxWidth: !isMobile ? "50vw" : "auto",
            fontSize: "16px",
            lineHeight: "1.6875",
            fontFamily: "'Open Sans', sans-serif",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#106cb7!important",
            mt: 2,
            mb: 2,
            px: 2,
          }}
        >
          <Button
            startIcon={
              <Assignment
                sx={{ fontSize: "5rem!important", color: "#106cb7" }}
              />
            }
            sx={{
              padding: "1rem 2rem",
              color: "#106cb7!important",
              background: "#106cb720",
            }}
          >
            <Typography variant="h3" sx={{ color: "#106cb7!important" }}>
              Volunteer Today!
            </Typography>
          </Button>
        </Grid>
        <Grid
          item
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            maxWidth: !isMobile ? "50vw" : "auto",
            fontSize: "8px",
            lineHeight: "1.6875",
            fontFamily: "'Open Sans', sans-serif",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#106cb7!important",
            mt: 2,
            mb: 2,
            px: 2,
            border: " 1px solid #106cb7!important",
            borderRadius: ".25rem"
          }}
        >
          
            <Typography variant="h6" sx={{ color: "#106cb7!important" }}>
              By authority: Deb Jung for County Executive, Judith A. Center; Treasurer.
            </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
