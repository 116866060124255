import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "transparent!important",
    color: "#000",
    boxShadow: "none",
    position: "absolute!important",
    width: "100%",
  },
  logo: {
    height: "79px",
    marginRight: theme.spacing(2),
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  menuButton: {
    color: "white!important",
    background: "#106cb7!important",
    padding: "0.25rem 1rem!important",
    margin: "0 .25rem!important",
    borderRadius: "0!important",
    textTransform: "lowercase!important",
  },
  link: {
    textDecoration: "none",
    color: "white!important",
    fontFamily: "'Montserrat'",
    fontSize: "2rem",
    lineHeight: "1.25",
    padding: "0 1rem"
  },
  a: {
    borderRadius: "0!important",
  },
  toolbar: {
    justifyContent: "space-between",
  },
  menuItem: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  drawerList: {
    width: 250,
  },
  menuIcon: {
    color: theme.palette.primary.main,
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    setDrawerOpen(false); // Close the drawer after clicking
  };
  
  const drawerList = () => (
    <div
      className={classes.drawerList}
      onClick={(e) => {
        if (e.target.tagName !== "A") toggleDrawer(false);
      }}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItemButton onClick={() => scrollToSection("meet-deb")}>
          <ListItemText primary="MEET DEB" />
        </ListItemButton>
        <ListItemButton onClick={() => scrollToSection("learn-more-deb")}>
          <ListItemText primary="VOLUNTEER" />
        </ListItemButton>
        <ListItemButton
          component="a"
          href="https://secure.ngpvan.com/gZxHJ61qgEyCR0_1Smu0xg2"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListItemText primary="DONATE" />
        </ListItemButton>
      </List>
    </div>
  );

  return (
    <AppBar
      className={classes.appBar}
      sx={{ background: "transparent", py: 1 }}
    >
      <Toolbar className={classes.toolbar}>
        <Box display={{ xs: "none", md: "flex" }}>
          <Button className={classes.menuButton}>
            <Link
              to="/"
              onClick={(e) => {
                e.preventDefault();
                const section = document.getElementById("meet-deb");
                if (section) {
                  section.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }
              }}
              className={`${classes.link} ${classes.menuItem}`}
            >
              meet Deb
            </Link>
          </Button>
          <Button className={classes.menuButton}>
            <Link
               to="/"
               onClick={(e) => {
                 e.preventDefault();
                 const section = document.getElementById("learn-more-deb");
                 if (section) {
                   section.scrollIntoView({
                     behavior: "smooth",
                     block: "start",
                   });
                 }
               }}
              className={`${classes.link} ${classes.menuItem}`}
             
            >
              volunteer
            </Link>
          </Button>
          <Button className={classes.menuButton}  sx={{background: "red!important"}}>
            <Link
              target="_blank"
              to="https://secure.ngpvan.com/gZxHJ61qgEyCR0_1Smu0xg2"
              className={`${classes.link} ${classes.menuItem}`}
            >
              donate
            </Link>
          </Button>
        </Box>
        <Box display={{ xs: "flex", md: "none" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
        </Box>
      </Toolbar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerList()}
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
